<template>
    <div>
        <div>
            <div class="success-title flex flex-x flex-y">
                <van-icon name="success" />扫码成功
            </div>
            <div class="qrcode">
                <img :src="qrcode"/>
            </div>
            <div class="identify">长按识别二维码关注公众号</div>
            <div class="identify">即可随时查看报告！</div>
        </div>
    </div>
</template>
<script>
    import { makecode , getDomain } from "@/utils";
    import { mapState, mapActions, mapMutations } from "vuex";
    export default {
        name: 'qr_add',
        components:{},
        data(){ 
            return {
                qrcode:''
            }
        },
        created:function(){
            this.axiosCustomBind({
                data:{
                    account_id:this.$route.params.id
                },
                fun:()=>{
                    this.axiosQr({
                        data:{
                            report_id:0,
                            appid:getDomain()
                        // appid:'wx0588aef9780e1932'
                        },
                        fun:(data)=>{
                            this.qrcode = data.qr_code;
                        }
                    })
                }
            })
        },
        computed:{
            
        },
        methods: {
            ...mapActions('redEnvelope',['axiosCustomBind','axiosQr']),
        }
        
    }
</script>

<style lang="scss" scoped rel="stylesheet/scss">
    @import 'qr_add';
</style>